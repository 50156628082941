import React from 'react';
import './Social.css';
import ResponsiveAppBar from './ResponsiveAppBar.js'

function Social() {
    return (
        <div className="Social-Header">
            <ResponsiveAppBar/>
            <div className="contentDiv">
                <a href="https://twitter.com/DontStopOil" className="Twitter"><div className="Xlogo"></div></a>
                <a href="https://www.tiktok.com/@dontstopoil" className="TikTok"><div className="Tlogo"></div></a>
                <a href="https://www.youtube.com/@DontStopOilNow" className="Youtube"><div className="Ylogo"></div></a>
                <a href="https://www.instagram.com/justdontstopoil" className="Instagram"><div className="Instagramlogo"></div></a>
               
            </div>
        </div>
    )
}

export default Social
