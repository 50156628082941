import React from 'react';
import './Error.css';
import ResponsiveAppBar from './ResponsiveAppBar.js';
import alexanderRadio from './BAD3Dog.png';
import Footer from './footer.js';




function Error() {
    return (
        <div className="Error-header">
            <ResponsiveAppBar />
            
           <div className="AboutError">
            <div className="RadioShadow"><img className="AlexanderRadio" src={alexanderRadio} alt='alexander radio' /></div>
            <div className="The404">Error 404!</div>
            <div className="Explain404">Page Not Found!</div>
            <Footer />
            </div>
   
  </div>
    )
}

export default Error
