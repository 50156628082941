import React from 'react';
import './footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="thefooter">
      <p className="footerP">© 2024 Interplanetary Creations LLC. All rights reserved.</p>
      {/*
      <p>
        <a href="/privacy-policy">Privacy Policy</a> | 
        <a href="/terms-of-service">Terms of Service</a>
      </p>
      */}
    </footer>
  );
};

export default Footer;
